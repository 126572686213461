/*
    Three main buttons
    - Border or solid (default)
        - Comes in black or white borders or solid in different colors
    - Link
        - With svg arrow, can be in different colors
*/
@mixin u-button($list: (), $default: true) {

    @if contains($list, link) {
        @if $default {
            display: inline-block;
            padding: 0;
            color: $link-color;
            font-family: $font-base;
            font-weight: $medium;
            border: none;
            border-radius: 0;
            outline: none;
            background: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            transition: all .2s ease-in;

            &:hover {
                text-decoration: underline;

                @media(hover: none) {
                    text-decoration: none;
                }
            }

            &::after {
                display: none;
            }
        }
    } @else {
        @if $default {
            display: inline-block;
            position: relative;
            max-width: 296px;
            min-width: 145px;
            height: 50px;
            padding: 0 20px;
            color: white;
            font-size: 1.6rem;
            font-weight: $bold;
            font-family: $font-base;
            line-height: 50px;
            text-decoration: none;
            border: none;
            border-radius: 4px;
            background: $primary-color;
            box-shadow: none;
            -webkit-appearance: none;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                border-radius: 4px;
                background: rgba(black, .2);
                transition: opacity 0.3s ease-out;
            }

            span:not(:global(.sr-only)) {
                position: relative;
                z-index: 1;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }
            }

            @include media(xxs) {
                max-width: none;
            }

            @include media(md) {
                padding-left: 37px;
                padding-right: 37px;
            }
        }

        @if contains($list, block) {
            display: block;
            width: 100%;
            max-width: none;
        }

        @if contains($list, shadow) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: transparent;
                box-shadow: -4px 4px 8px black;
                opacity: 0.2;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
            }

            &:hover {
                &::before {
                    opacity: 0.3;

                    @media(hover: none) {
                        opacity: 0;
                    }
                }
            }
        }

        @if contains($list, secondary) {
            background: $secondary-color;
        }

        @if contains($list, border) {
            height: 52px;
            padding: 0 17px;
            color: $primary-color;
            border: 2px solid $primary-color;
            background: transparent;
            transition: all 0.3s ease-out;

            @include media(md) {
                padding-left: 34px;
                padding-right: 34px;
            }

            &:hover {
                color: white;
                background: $primary-color;
            }

            &::after {
                display: none;
            }
        }

        @if contains($list, ghost) {
            color: white;
            font-size: 1.4rem;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 8px;
            background: transparent;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                opacity: 0;
                border-radius: 8px;
                background: $billboard-button-hover-color;
                z-index: z(behind);
                transition: opacity 0.3s ease-out;
            }

            &::after {
                opacity: 1;
                background: transparent;
                border: 1px solid white;
            }

            &:hover {
                border-color: transparent;
                background-color: transparent;

                &::before {
                    opacity: 1;
                }

                &::after {
                    opacity: 0;
                }
            }

            @if contains($list, secondary) {
                &::before {
                    background: rgba(white, .2);
                }
            }

            @if contains($list, black) {
                color: $text-color;

                &::before {
                    border: 1px solid $button-ghost-border-before-color;
                    background: transparent;
                    z-index: inherit;
                }
                &::after {
                    border-color: $button-ghost-border-after-color;
                    z-index: inherit;
                }

            }
        }

        @if contains($list, small) {
            height: 40px;
            line-height: 40px;
            font-size: 1.4rem;

            @include media(md) {
                padding: 0 30px;
            }
        }

        @if contains($list, large) {
            min-width: 190px;
        }
    }
}
