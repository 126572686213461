@import 'Styles/Base/includes';

/**
*
* CookiePopup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CookiePopup {
    $root: &;

    &__Button {
        position: fixed;
        bottom: 12px;
        left: 12px;
        padding: 7px 10px 6px 42px;
        color: black;
        font-size: 1.4rem;
        font-family: $font-base;
        border: none;
        border-radius: 4px;
        box-shadow: 0 1px 8px 0 rgba(black, .12);
        outline: none;
        appearance: none;
        cursor: pointer;
        z-index: z(CookiePopup);
        transition: all .3s ease-in-out;

        &:hover {
            background: white;
        }

        #{$root}--Open & {
            opacity: 0;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 12px;
            width: 21px;
            height: 21px;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }

    &__Popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, .6);
        z-index: z(CookiePopup);
    }

    &__Container {
        @include u-wrap(cookie);
    }

    &__Scroll {
        max-height: 100vh;
        overflow-y: auto;

        @include media-max(xs) {
            padding-bottom: 60px;
        }
    }

    &__Content {
        padding: 16px;
        background: white;

        @include media(sm) {
            padding: 32px;
        }
    }

    &__Title {
        position: relative;
        margin-bottom: 8px;
        font-size: 2rem;
        font-weight: $bold;
        line-height: 1.5;

        @include media(sm) {
            font-size: 2.4rem;
        }
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;

        a {
            color: $text-color;
            text-decoration: underline;
            transition: color .3s ease-in-out;

            &:hover {
                color: $link-color;

                @media(hover: none) {
                    color: $text-color;
                }
            }
        }
    }

    &__Buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;

        @include media(sm) {
            flex-direction: row;
        }
    }

    &__Accept,
    &__Deny {
        flex: 0 0 auto;
        width: 100% !important;
        height: 50px !important;
        padding: 0 12px !important;
        font-size: 1.6rem !important;
        line-height: normal !important;
        text-transform: none !important;

        span {
            position: relative;
        }

        @include media(sm) {
            width: calc(50% - 4px) !important;
        }
    }

    &__Link {
        @include u-button(link);

        display: block;
        position: relative;
        padding-right: 24px;
        margin-top: 24px;
        color: $text-color;
        font-size: 1.6rem;
        font-weight: $bold;

        &:hover {
            color: $text-color;
            text-decoration: none;

            &::after {
                opacity: 1;
            }

            @media(hover: none) {
                color: $text-color;

                &::after {
                    opacity: 0;
                }
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            right: 0;
            width: 12px;
            height: 10px;
            margin: auto;
            background: url('#{$basepath}img/bg-icons/base/dropdown.svg') no-repeat center center;
            background-size: contain;
            transition: transform .3s ease-in-out;

            #{$root}--Expanded & {
                transform: rotate(180deg);
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 24px;
            height: 2px;
            background: $text-color;
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }
    }

    &__Settings {
        padding-top: 16px;

        @include media(sm) {
            padding-top: 24px;
        }
    }

    &__Submit {
        height: 50px !important;
        margin-top: 8px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-size: 1.6rem !important;
        line-height: normal !important;
        text-transform: none !important;

        span {
            position: relative;
        }

    }
}
