/*
    Three main buttons
    - Border or solid (default)
        - Comes in black or white borders or solid in different colors
*/
@mixin u-button($list: (), $default: true) {
    @if contains($list, link) {
        @if $default {
            display: inline-block;
            padding: 0;
            color: palette(green);
            font-family: $font-base;
            font-weight: $medium;
            border: none;
            border-radius: 0;
            outline: none;
            background: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            transition: all .2s ease-in;

            &:hover {
                color: palette(green, hover);
                text-decoration: underline;

                @media(hover: none) {
                    color: palette(green);
                }
            }
        }
    } @else if contains($list, arrow) {
        @if $default {
            padding: 0;
            font-size: 1.4rem;
            font-weight: $bold;
            letter-spacing: 1px;
            text-transform: uppercase;
            border: none;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;

            svg {
                display: inline-block;
                position: relative;
                left: 0px;
                width: 13px;
                height: 10px;
                margin-right: 9px;
                fill: palette(red);
                transition: left .2s;

                @if contains($list, large) {
                    width: 20px;
                    height: 14px;
                    margin-right: 11px;
                }
            }
        }

        @if contains($list, large) {
            font-size: 1.8rem;
        }
    } @else {
        @if $default {
            display: inline-block;
            position: relative;
            max-width: 296px;
            min-width: 116px;
            height: 45px;
            padding: 0 20px;
            color: black;
            font-size: 1.4rem;
            font-weight: $bold;
            font-family: $font-base;
            line-height: 39px;
            text-decoration: none;
            text-transform: uppercase;
            border: 2px solid black;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            cursor: pointer;

            @include media(xxs) {
                max-width: none;
            }

            @include media(md) {
                padding-left: 37px;
                padding-right: 37px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: black;
                opacity: 0;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
            }

            &:hover {
                &::before {
                    opacity: 1;

                    @media(hover: none) {
                        opacity: 0;
                    }
                }
            }
        }

        @if contains($list, large) {
            min-width: 163px;
        }

        @if contains($list, block) {
            display: block;
            width: 100%;
            max-width: none;
        }

        @if contains($list, solid) {
            height: 40px;
            min-width: 135px;
            padding: 0 40px;
            color: white;
            font-size: 1.4rem;
            text-transform: none;
            line-height: 40px;
            transition: background .2s, border-color .2s;

            @include media(md) {
                padding-right: 60px;
                padding-left: 60px;
            }

            span:not(:global(.sr-only)) {
                position: relative;
            }

            @if contains($list, large) {
                min-width: 163px;
                height: 50px;
                font-size: 1.6rem;
                line-height: 50px;
            }
        }

        @if contains($list, shadow) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: transparent;
                box-shadow: -4px 4px 8px palette(black);
                opacity: 0.2;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
            }

            &:hover {
                &::before {
                    opacity: 0.3;

                    @media(hover: none) {
                        opacity: 0;
                    }
                }
            }
        }

        @if contains($list, white) {
            border-color: white;

            @if contains($list, solid) {
                color: black;
                background: white;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);

                &:hover {
                    opacity: .9;
                }
            } @else {
                color: white;

                &:hover {
                    color: black;
                }
            }

            &::before {
                background: rgba(white, 1);
            }
        }

        @if contains($list, green) {
            @if contains($list, solid) {
                border: none;
                background: palette(green);

                &:hover {
                    background: palette(green, hover);
                }

                &::before {
                    display: none;
                }
            } @else {
                color: palette(white);
                border-color: palette(black);
                background: palette(green);

                &::before {
                    background: rgba(palette(green), .1);
                }
            }
        }

        @if contains($list, small) {
            min-width: 0;
            height: 32px;
            padding: 0 20px;
            font-size: 1.2rem;
            line-height: 32px;
        }
    }
}
