@import 'Styles/Medborgarskolan/includes';

/**
*
* CookiePopup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CookiePopup.Base.module';

.CookiePopup {
    $root: &;

    &__Button {
        padding-top: 8px;
        padding-bottom: 7px;
        background: $blue-10;

        &::before {
            top: 4px;
            background-image: url('#{$basepath}img/bg-icons/base/cookie.webp');
        }
    }

    &__Title {
        padding-left: 36px;
        font-weight: $bold;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 0;
            width: 24px;
            height: 24px;
            margin: auto;
            background: url('#{$basepath}img/bg-icons/base/cookie.webp') no-repeat center center;
            background-size: contain;
        }
    }

    &__Accept {
        @include u-button(primary);
    }

    &__Deny {
        @include u-button(secondary);

        letter-spacing: 0;
    }

    &__Link {
        font-weight: $bold;
    }

    &__Submit {
        @include u-button(secondary);
    }
}
