@import 'Styles/Kulturama/includes';

/**
*
* CookiePopup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CookiePopup.Base.module';

.CookiePopup {
    $root: &;

    &__Button {
        padding-top: 6px;
        background: palette(black, 4);

        &::before {
            background-image: url('#{$basepath}img/bg-icons/kulturama/cookie--purple.svg');
        }
    }

    &__Accept {
        @include u-button(pink solid large);
    }

    &__Deny {
        @include u-button();

        &:hover {
            color: white;
        }
    }

    &__Submit {
        @include u-button();

        span {
            position: relative;
        }

        &:hover {
            color: white;
        }
    }
}
