/*
    Three main buttons
    - Border or solid (default)
        - Comes in black or white borders or solid in different colors
    - Link
        - With svg arrow, can be in different colors
*/
@mixin u-button($list: (), $default: true) {

    @if contains($list, link) {
        @if $default {
            display: inline-block;
            padding: 0;
            color: palette(pink);
            font-family: $font-base;
            font-weight: $medium;
            border: none;
            border-radius: 0;
            outline: none;
            background: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            transition: all .2s ease-in;

            &:hover {
                color: palette(pink, hover);
                text-decoration: underline;

                @media(hover: none) {
                    color: palette(pink);
                }
            }
        }
    } @else if contains($list, arrow) {
        @if $default {
            padding: 0;
            font-size: 1.4rem;
            font-weight: $bold;
            letter-spacing: 1px;
            text-transform: uppercase;
            border: none;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;

                svg {
                    left: 2px;

                    @if contains($list, large) {
                        left: 3px;
                    }
                }

                @media(hover: none) {
                    text-decoration: none;
                }
            }

            svg {
                display: inline-block;
                position: relative;
                left: 0px;
                width: 13px;
                height: 10px;
                margin-right: 9px;
                fill: palette(red);
                transition: left .2s;

                @if contains($list, pink) {
                    fill: palette(pink);
                }

                @if contains($list, purple) {
                    fill: palette(purple);
                }

                @if contains($list, red) {
                    fill: palette(red);
                }

                @if contains($list, large) {
                    width: 20px;
                    height: 14px;
                    margin-right: 11px;
                }
            }
        }

        @if contains($list, large) {
            font-size: 1.8rem;
        }
    } @else {
        @if $default {
            display: inline-block;
            position: relative;
            max-width: 296px;
            min-width: 116px;
            height: 45px;
            padding: 0 20px;
            color: black;
            font-size: 1.4rem;
            font-weight: $bold;
            font-family: $font-base;
            line-height: 39px;
            text-decoration: none;
            text-transform: uppercase;
            border: 3px solid black;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            cursor: pointer;

            @include media(xxs) {
                max-width: none;
            }

            @include media(md) {
                padding-left: 37px;
                padding-right: 37px;
            }

            span:not(:global(.sr-only)) {
                position: relative;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: black;
                opacity: 0;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
            }

            &:hover {
                &::before {
                    opacity: 1;

                    @media(hover: none) {
                        opacity: 0;
                    }
                }
            }
        }

        @if contains($list, large) {
            min-width: 163px;
        }

        @if contains($list, border-arrow) {
            min-width: 0;
            height: 50px;
            padding: 0 14px 1px #{14 + 20 + 10px};
            letter-spacing: 1px;
            line-height: 43px;

            @include media(md) {
                padding-left: #{18 + 24 + 10px};
                padding-right: 18px;
            }

            &::before {
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
            }

            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                left: 14px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                background-image: url('../#{$basepath}img/bg-icons/kulturama/arrow--black.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                @include media(md) {
                    left: 18px;
                    width: 24px;
                    height: 24px;
                }
            }

            &:hover {
                color: white;

                &::before {
                    opacity: 1;
                }

                &::after {
                    background-image: url('../#{$basepath}img/bg-icons/kulturama/arrow--white.svg');
                }
            }
        }

        @if contains($list, shadow) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: transparent;
                box-shadow: -4px 4px 8px palette(black);
                opacity: 0.2;
                transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
            }

            &:hover {
                &::before {
                    opacity: 0.3;

                    @media(hover: none) {
                        opacity: 0;
                    }
                }
            }
        }

        @if contains($list, block) {
            display: block;
            width: 100%;
            max-width: none;
        }

        @if contains($list, solid) {
            height: 40px;
            min-width: 135px;
            padding: 0 40px;
            color: white;
            font-size: 1.4rem;
            text-transform: none;
            line-height: 40px;
            transition: background .2s, border-color .2s;

            @include media(md) {
                padding-right: 60px;
                padding-left: 60px;
            }

            span:not(:global(.sr-only)) {
                position: relative;
            }

            @if contains($list, large) {
                min-width: 163px;
                height: 50px;
                font-size: 1.6rem;
                line-height: 50px;
            }
        }

        @if contains($list, white) {
            border-color: white;

            @if contains($list, solid) {
                color: black;
                background: white;
            } @else {
                color: white;

                &:hover {
                    color: black;
                }
            }

            &::before {
                background: rgba(white, 1);
            }
        }

        @if contains($list, pink) {
            @if contains($list, solid) {
                border: none;
                background: palette(pink);

                &:hover {
                    background: palette(pink, hover);
                }

                &::before {
                    display: none;
                }
            } @else {
                color: palette(pink);
                border-color: palette(pink);

                &::before {
                    background: rgba(palette(pink), .1);
                }
            }
        }

        @if contains($list, purple) {

            @if contains($list, solid) {
                border: none;
                background: palette(purple);

                &:hover {
                    background: palette(purple, hover);
                }

                &::before {
                    display: none;
                }
            } @else {
                color: palette(purple);
                border-color: palette(purple);

                &::before {
                    background: rgba(palette(purple), .1);
                }
            }
        }

        @if contains($list, disabled) {
            color: white;
            background: palette(black, 15);
            cursor: auto;
            transition: background .2s;

            &:hover {
                background: palette(black, 15);

                @media(hover: none) {
                    background: palette(black, 15);
                }
            }
        }

        @if contains($list, small) {
            min-width: 0;
            height: 36px;
            padding: 0 20px;
            font-size: 1.4rem;
            line-height: 36px;
        }
    }
}
