@import 'Styles/Tillskararakademin/includes';

/**
*
* CookiePopup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CookiePopup.Base.module';

.CookiePopup {
    $root: &;

    &__Button {
        background: palette(green, 5);

        &::before {
            background-image: url('#{$basepath}img/bg-icons/tillskararakademin/cookie--green.svg');
        }
    }

    &__Title {
        font-size: 2.8rem;
    }

    &__Accept {
        @include u-button(green solid large);
    }

    &__Deny {
        @include u-button();

        &:hover {
            color: white;
        }
    }

    &__Submit {
        @include u-button();

        span {
            position: relative;
        }

        &:hover {
            color: white;
        }
    }
}
