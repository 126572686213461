@import 'Styles/Base/includes';

.BasePage {
    &__Container {
        :global(.MenuMobileActive) & {
            display: none;

            @include media(md) {
                display: block;
            }
        }
    }

    &__Footer {
        :global(.MenuMobileActive) & {
            display: none;

            @include media(md) {
                display: block;
            }
        }
    }
}