@import 'Styles/Pahlmans/includes';

/**
*
* CookiePopup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CookiePopup.Base.module';

.CookiePopup {
    $root: &;

    color: palette(black, 86);

    &__Button {
        background: $cookie-accordion;

        &::before {
            background-image: $cookie;
        }
    }

    &__Title {
        font-weight: $medium;
    }

    &__Text {
        a {
            color: palette(black, 86);

            &:hover {
                @media(hover: none) {
                    color: palette(black, 86);
                }
            }
        }
    }

    &__Accept {
        @include u-button();
    }

    &__Deny {
        @include u-button(border);
    }

    &__Link {
        color: palette(black, 86);

        &:hover {
            color: palette(black, 86);

            @media(hover: none) {
                color: palette(black, 86);
            }
        }
    }

    &__Submit {
        @include u-button(border);
    }
}
